@media (min-width: 481px) {
    .headerLogo {
        display: flex;
        width: 20%;
        height: 8%;
        flex-shrink: 0;
        position: fixed;
        top: 0;
        left: 0;
        margin: 3% 5%;
    }
}
.app_header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 85%;
    height: 12%;
    color: white;
    position: fixed;
    top:0;
    z-index: 999;
    background-color: #FDF7ED;
}

