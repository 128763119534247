.container {
  /* width: 80%; */
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: #FDF7ED;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
}

.toast-container {
  width: 195px;
  margin-top: 20px;
  margin-left: 25%;
  text-align: center;
  
}

.shadow {
  border-radius: 0px 0px 8px 8px;
  background: var(--Brick, #FA7F39);
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  z-index: 0;
}

.back-btn{
  position: absolute;
  z-index: 2;
  left: 5px;
  top: 50%;
  font-size: 1.5rem;
}

.text-brick{
  color: #FA7F39;
}

.profile {
  display: flex;
  align-items: center;
  flex-direction: column;
  position:relative;
  margin-top: 40px;
}

.profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.birthday {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.card {
  margin: 12px;
  padding: 0 12px;
}

.gift-preference,
.gift-recommendations {

  min-height: 100px;
}

.gift-types {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}

.gift-type-btn {
  width: 92.205px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--Soft-Border-Gray, #D9D9D9);
  background: var(#ffffff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gift-type-btn.active {
  background: var(--Background-Cream, #FDF7ED);
}

.gift-type-btn__image--experiences {
  width: 57.628px;
  height: 40px;
  flex-shrink: 0;
  background: url("../assets/music_keys.png"), rgba(0, 0, 0, 0) 50% / contain no-repeat;
}

.gift-type-btn__image--presents {
  width: 57.628px;
  height: 50px;
  flex-shrink: 0;
  background: url("../assets/pink_gift_box_with_white_ribbon.png"), rgba(0, 0, 0, 0) 50% / contain no-repeat;
}

.gift-type-btn__image--donations {
  width: 57.628px;
  height: 50px;
  flex-shrink: 0;
  background: url("../assets/receive_cash.png"), rgba(0, 0, 0, 0) 50% / contain no-repeat;
}

.gift-type-btn__text {
  color: var(--Primary-Text, #3D3C3C);
  text-align: center;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #53CF85;
  cursor: pointer;
  z-index: 1;
  position: relative;
  color: #FDF7ED;
}

.edit-btn {
  margin-top: 20px;
  display: block;
}

.description {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.border {
  border-right: 1px solid #D9D9D9;
}

.tab-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.tab-container span {
  padding: 10px 20px;
  cursor: pointer;
  color: #3D3C3C;
}

.tab-container span.active-tab {
  border-radius: 5px;
  color: black;
  text-decoration: underline;
}



.fav-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}

.personalized-recs--container {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  min-height: 469px;
  padding-top: 21px;
  border: 1px solid var(--Soft-Border-Gray, #D9D9D9);

}

.personalized-recs--container--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.personalized-recs--grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  padding: 24px;
}

.grid-item {
  /* background-color: #F5F5F5;
  border: 1px solid #D9D9D9; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 5px;
}

.product-pic {
  height: 128px;
  width: 128px;
  border-radius: 8px;
  border: 1px solid var(--Soft-Border-Gray, #D9D9D9);
  background: var(--White, #FFF);
  flex-shrink: 0;
  position: relative;
  object-fit: cover;
}


.product-heart {
  width: 32px;
  /* Adjust based on your heart icon size */
  height: 32px;
  /* margin-bottom: 24px; */
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  background-color: #FDF7ED;
  border: 2px solid #D9D9D9;
  display: flex;
  justify-content: space-around;
  padding-top: 3px;
}

.product-name {
  font-size: 16px;
  font-weight: 500;
}

.product-price {
  font-size: 14px;
  font-weight: 400;
}

.no-tags-text{
  text-align: center;
  margin-top: 100px;
}

.action-btn{
  display:flex;
  flex-direction: column;
}

.spinner-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}