@media (min-width: 481px) {
    .signup-container {
        background: var(--Background-Cream, #FDF7ED);
        min-height: 100vh;
        padding-top: 15%;
        padding-bottom: 10%;
        display: flex;
        overflow-y: scroll;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .back-button {
        align-self: flex-start;
        padding-left: 10%;
    }

    .signup-container > h1 {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        width: 62%;
    }

    .form-select-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5%;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
    }

    .form-group > label {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
    }

    .form-group > input {
        width: 100%;
        font-size: 20px;
        border-radius: 3px;
        border: 1px solid var(--Bright-White, #FFF);
        background: var(--Bright-White, #FFF);
        padding: 1% 1.5%
    }

    .form-group > select {
        font-size: 20px;
        border-radius: 3px;
        border: 4px solid var(--Bright-White, #FFF);
        background: var(--Bright-White, #FFF);
        padding: 1% 1.5%
    }
    
    .form-group > p {
        padding-top: 1%;
    }


    .signup-button {
        background-color: #53CF85;
        color: #fff;
        margin: 0 auto;
        width: 25%;
        padding: 3% 2%;
        border-radius: 15px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 3%;
    }
}

@media(max-width: 480px) {
    .signup-container {
        width: 100vw;
        min-height: 100vh;
        padding: 30% 2% 5% 2%;
        overflow-y: scroll;
        background-color: #FDF7ED;
        display: flex;
        flex-direction: column;
    }

    .signup-container > div {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .signup-container > h1 {
        color: #3d3c3c;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;

    }

    .signup-container > div > a {
        color: black;
        font-size: 20px;
        padding: 1% 10%;
    }

    .signup-container > form {
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .signup-container > form > div {
        display: flex;
        flex-direction: column;
    }

    .signup-container > form > div:nth-child(3) > p {
        margin-top: 2%;
        width: 87%;
    }

    .signup-container > form > div > label, .signup-container > form > div:nth-child(7) > div > label, .signup-container > form > div:nth-child(11) > div > label {
        margin-bottom: 2%;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.28px;
    }

    .signup-container > form > div > input {
        background-color: #fff;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        width: 95%;
        padding: 3.5%;
        align-self: center;
    }

    .signup-container > form > div:nth-child(11) {
        width: 95%;
        flex-direction: row;
        justify-content: space-between;
    }

    .signup-container > form > div:nth-child(11) > div {
        width: 45%;
        display: flex;
        flex-direction: column;
    }

    .signup-container > form > div:nth-child(11) > div > input, .signup-container > form > div:nth-child(11) > div > select {
        background-color: #fff;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        border-radius: 4px;
        width: 95%;
        padding: 5%;
    }

    .signup-container > form > button {
        background-color: #53CF85;
        color: #fff;
        margin: 0 auto;
        width: 55%;
        padding: 4%;
        border-radius: 15px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-self: center;
    }
}

.selector{
    background-color: #fff;
}

.selector div {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
}