.tag-container {
    min-height: 100vh;
    width: 100%;
    padding: 110px 15px 15px;
    background-color: #FDF7ED;
}

.toast-container {
    width: 195px;
    margin-top: 20px;
    margin-left: 25%;
    text-align: center;
}

.tag-container > div.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 905x;
}

.tag-container > div.header > h1 {
    margin-bottom: 5px;
}

.tag-container > div.header > p {
    width: 330px;
}

.tag-container > div.hobbies {
    margin-top: -15px;
    height: 140px;
    display: flex;
}

.tag-container > div.hobbies > img {
    width: 113px;
    height: 113px;
}

.tag-container > div.hobbies > img:nth-child(2) {
    transform: scale(1.5);
    align-self: flex-end;
    width: 120px;
    height: 113px;
}

.tag-container > .tag-input {
    width: 358px;
    height: 48px;
    border-radius: 40px;
    background-color: #FFF;
    padding: 10px;
    padding-left: 30px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    background-image: url('../assets/searchIcon.png');
    background-repeat: no-repeat;
    background-position: 2% 50%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
    margin: 10px 0 25px;

}

.add-tags-container > h3 {
    font-size: 20px;
    color: #3D3C3C;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 5px;
}

.tag-button {
    height: 40px;
    padding: 8px 16px;
    margin-right: 10px;
    margin-bottom: 10px; 
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background-color: #fff;
    transition: background-color 0.3s;
    color: #3D3C3C;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 20.8px;
}

.selected {
    background-color: #53CF85;
    color: #fff;
}

.tag-container > div:nth-child(5) > div > h3 {
    color: #3D3C3C;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 5px;
}

.tag-container > div:nth-child(5) > div > button.tag-button {
    color: #3D3C3C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px
}

.complete-button {
    display: block;
    width: 264px;
    height: 60px;
    margin: 10px auto 0;
    padding: 10px 20px;
    background-color: #53CF85;
    color: white;
    border: none;
    border-radius: 20px;
    margin-bottom: 100px;
}

