@media(max-width:480px) {
    .footer-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        border-top: 1px solid #D9D9D9; 
        text-decoration: none;
        background-color: #FDF7ED;
        z-index: 10;
       
    }
    .footer-container > a > div {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: black;
    }

    .footer-container > a > div > p{
        text-align: center;
        text-decoration: none;        
    }

    .footer-container > a {
        text-decoration: none;
        color: white;
    }
    .footer-container > a > div > img {
        width: 32px;
        height: 31.722px;
    }
    img.gift {
        transform: rotate(-50.773deg);
       
       
    }
}