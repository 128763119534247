@media (max-width: 480px) {
  .friends-container {
    background-color: #FDF7ED;
    padding: 120px 20px;
    position: absolute;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }

  .friends-container > .search-bar {
    width: 358px;
    height: 48px;
    padding: 10px;
    padding-left: 30px;
    border: 1px solid #D9D9D9;
    border-radius: 40px;
    background-color: white;
    margin-bottom: 10px;
    box-sizing: border-box;
    background-image: url('../assets/searchIcon.png');
    background-repeat: no-repeat;
    background-position: 2% 50%;
    font-family: Pilcrow Rounded;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px
  }

  .friends-container > .reminders {
    width: 340px;
    height: 122px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background-color: #FFF;
    margin: 0 auto;
    display: flex;
    margin-bottom: 50px;
  }

  .friends-container > .reminders > img:nth-child(1) {
    width: 116px;
    height: 175px;
    margin-right: -40px;
    /* z-index: 999; */
  }

  .friends-container > .reminders > img:nth-child(2) {
    width: 68px;
    height: 150px;
    /* z-index: 999; */
  }

  .friends-container > .reminders > div {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }

  .friends-container > .reminders > div > p {
    /* border: 1px solid royalblue; */
    width: 80%;
    margin-left: -10px;
    color: #3D3C3C;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .friends-container > .reminders > h2 {
    width: 171px;
    color: #3D3C3C;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding-top: 30px;
    margin-left: 10px;

  }

  .friends-container > div:nth-child(3) > div.no-friends-yet {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    width: 358px;
    height: 100px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px 0px rgba(116, 138, 133, 0.40);
  }

  .friends-container > div:nth-child(3) > div.no-friends-yet > img {
    width: 99px;
    height: 99px;
  }

  .friends-container > div:nth-child(3) > div.no-friends-yet > p {
    width: 219px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px
  }

  .friends-container > button {
    height: 75px;
    position: fixed;
    bottom: 80px;
    right: 0;
    margin-right: 20px;
    margin-bottom: 10px;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    background: #53CF85;

    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    z-index: 1500;
  }

  .friends-container > button > span {
    font-size: 40px;
    margin-top: -15px;
    margin-bottom: -18px;
  }

  /*  ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~  */

  .itemButton {
    width: 100%;
    background-color: #FDF7ED;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 12px 0 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  }

  .confetti {
    border: "2px solid red";
    margin: "0 auto";

  }

  .item > div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
  }

  .item > div > img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  .item > div > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .item > div > div:nth-child(2) > p:nth-child(1) {
    color: #3D3C3C;
    width: max-content;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
    text-align: left;
  }
  
  .item > div > div:nth-child(2) > p:nth-child(2) {
    color: #3D3C3C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
    text-align: left;
  }

  .item > div > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    height: 60px;

  }

  .item > div > div:nth-child(3) > p:nth-child(1) {
    /* color: #FE6797; */
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .item > div > div:nth-child(3) > p:nth-child(2) {
    width: 68.886px;
    color: #3D3C3C;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px
  }

  .itemButton > div:nth-child(2) {
    /* border: 2px solid red; */
    width: 100%;
    padding: 8px 5px;
    color: #FDF7ED;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: right;
    z-index: -1;
    margin-top: -10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: border-bottom-left-radius 0.5s ease-in-out, border-bottom-right-radius 0.5s ease-in-out;
  }
  
  .itemButton > div:nth-child(2) > .dropdown {
    font-size: 20px;
    font-weight: 900;
  }
  
  .itemButton > div:nth-child(3) {
    color: black;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    height: 0; 
    transition: height 0.5s ease-in-out, overflow 0.5s ease-in-out;
  }
  
  .itemButton > div:nth-child(3).open {
    /* border: 1px solid royalblue; */
    height: 130px;
    overflow: scroll;
  }

  .itemButton > div:nth-child(3).open > p {
    font-size: 18px;
    font-weight: 900;
  }

  .itemButton > div:nth-child(3).open > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    color: black;
  }
  
  .itemButton > div:nth-child(3).open > a > img {
    border: 1px solid gray;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .itemButton > div:nth-child(3).open > a > p {
    width: fit-content;
  }

  .list {
    padding-bottom: 50px;
  }

  .list > h3 {
    /* border: 1px solid red; */
    width: max-content;
    margin-top: 20px;
    font-weight: 900;
    font-size: 20px;
  }

  .list > p {
    /* border: 1px solid red; */
    width: max-content;
    margin: 10px auto;
    font-size: 18px;
  }
  /* ~ ~ ~ ~ ~ ~ ~ ~ ~ ~  */

  .onboarding-overlay {
    /* border: 2px solid red; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }

  .onboarding-overlay > .onboarding-content {
    display: inline-flex;
    width: 328px;
    height: 379px;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .onboarding-overlay > .onboarding-content > h2 {
    color: #000;
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .onboarding-overlay > .onboarding-content > ul {
    font-size: 20px;
  }

  .onboarding-overlay > .onboarding-content > ul > li {
    margin-left: 30px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  .onboarding-overlay > .onboarding-content > button {
    width: 264px;
    height: 60px;
    border-radius: 20px;
    background-color: #53CF85;

    color: var(--Bright-White, #FFF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  /* ~~~~~~~~~~~~~~~~ */

  .onboarding-overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
  }

  .onboarding-overlay2 > .onboarding-content2 {
    display: inline-flex;
    width: 294px;
    height: 185px;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 75px;
  }

  .onboarding-overlay2 > .onboarding-content2 > h2 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    width: 230px;
  }

  .onboarding-overlay2 > .onboarding-content2 > p {
    color: #3D3C3C;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.8px;
    text-decoration-line: underline;
  }

  .onboarding-overlay2 > .onboarding-content2 > img {
    position: fixed;
    bottom: 100px;
    left: -25px;
    width: 259.327px;
    height: 259.327px;
    transform: rotate(15.634deg);
    flex-shrink: 0;
  }
}