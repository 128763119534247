.create-profile-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FDF7ED;;
    
}

.toast-container {
    width: 195px !important;
    margin-top: 20px !important;
    margin-left: 25% !important;
    text-align: center;
}

.create-profile-container > div:nth-child(1) {
    margin-right: 25%;
    margin-bottom: 10px;
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create-profile-container > div:nth-child(1) > h1 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -.5px;
    line-height: normal;

}

.create-profile-container > div:nth-child(1) > p {
    color: black;
    font-size: 20px;
}

.create-profile-container > form > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.create-profile-container > form > div:nth-child(1) > label {
    background-color: #c4c4c4;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background-color: #53CF85;
    color: white;
    font-weight: lighter;

}

.create-profile-container > form > div:nth-child(1) > p {
    color: var(--Primary-Text, #3D3C3C);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px
   
}


.create-profile-container > form > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.create-profile-container > form > div > label {
    color: #4A4A4A;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
    
}

.create-profile-container > form > div > input {
    width: 358px;
    height: 48px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #D9D9D9;  
    background-color: white;
}

.create-profile-container > form > div:nth-child(5) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 -20px;
   
}

.create-profile-container > form > div:nth-child(5) > div {
    display: flex;
    flex-direction: column;
}

.create-profile-container > form > div:nth-child(5) > div > label, .create-profile-container > form > div:nth-child(11) > label {
    color: #4A4A4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;

}

.create-profile-container > form > div:nth-child(5) > div > input {
    border-radius: 8px;
    border-radius: 4px;
    width: 95%;
    padding: 12px;
    outline: none;
    border: 1px solid #D9D9D9;
    background-color: white;
}

.create-profile-container > form > div:nth-child(5) > div > select {
    border-radius: 8px;
    border-radius: 4px;
    width: 130px;
    padding: 12px;
    border: none;
    outline: none;
    border: 1px solid #D9D9D9;
    background-color: white;
    
    
}

.create-profile-container > form > div:nth-child(9) > p {
    color: #4A4A4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
    margin-bottom: 5px;
}

.create-profile-container > form > div:nth-child(9) > div {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding-top: 4px;
}

.create-profile-container > form > div:nth-child(9) > div > button {
    height: 40px;
    padding: 8px 24px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background-color: white;
    color: black;

}

.create-profile-container > form > div:nth-child(11) > select {
    background-color: #C4C4C4;
    border-radius: 8px;
    border-radius: 4px;
    width: 100%;
    padding: 12px;
    border: none;
}

.create-profile-container > form > button {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 264px;
    height: 60px;
    color: #fff;
    background-color: #747474;
    border-radius: 20px;
    margin: 0 auto;
    background-color: #53CF85;
}

button.gift-type-active{
    background-color: #53CF85 !important;
    color: #fff !important;
}

.selector{
    background-color: #fff;
}

.selector div {
    padding-bottom: 11px !important;
    padding-top: 11px !important;
}