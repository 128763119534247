.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
}

.container {
  padding-top: 60px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 45px 20px;
  background-color: #FDF7ED;
  overflow-y: scroll;
}

.heading-container{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap:20px;
}

.save-btn {
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  border: 1px solid #D9D9D9;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #53CF85;
  color: white;
  width: 100%;
  border-radius: 1px !important;

}

.clear-btn {
  background-color: white;
  color: black;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 1px !important;

}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  margin: 0 -20px 35px -20px;
  overflow: visible;
  margin-top: 25px;
}

.row-container {
  flex-direction: column;
  border-bottom: solid;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.tag-button {
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
  color: black;
}

.tag-button.active{
  background: var(--Accent-Green, #53CF85);
  color: #FDF7ED;
}

.slider {
  width: 80%;
}

.slider-container {
  display: flex;
  justify-content: center;
  width: 80%;
  padding-left: 40px;
  padding-left: 40px;
}
