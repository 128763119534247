@media (min-width: 481px) {
    .landing-container {
        background: var(--Background-Cream, #FDF7ED);
        padding-top: 8%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .desktopLogo {
        width: 20%;
        height: 10%;
        flex-shrink: 0;
        position: fixed;
        top: 0;
        left: 0;
        margin: 5% 2%;
    }

    .mobileLogo {
        display: none;
    }

    .desktopIntro {
        width: 30%;
        height: 33%;
        flex-shrink: 0;
        margin-bottom: 5%;
    }

    .landing-container > h1 {
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }

    .landing-container > div {
        width: 40%;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
    }

    .landing-container > p {
        font-size: 20px;
    }

    .landing-container > div > p {
        padding: 1.5%;
    }

    .get-started {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18%;
        height: 6%;
        flex-shrink: 0;
        color: #fff;
        background-color: #53CF85;
        text-decoration: none;
        border-radius: 20px;
        margin: 2%;
        padding: 2%;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.48px;
    }
    .log-in {
        color: black;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
        text-decoration-line: underline;
    }
}

@media (max-width: 480px) {
    .landing-container {
        height: 100vh;
        padding-bottom: 8%;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        background-color: #FDF7ED;
    }

    .desktopLogo, .desktopIntro {
        display: none;
    }

    .mobileLogo {
        border-bottom-left-radius: 3%;
        border-bottom-right-radius: 3%;
        height: 80%;
        width: 100%;
        display: block;
    }
    

    .landing-container > h1 {
        color: #fff;
        width: 55%;
        height: 11%;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: -90% auto 90%;
        z-index: 999999;

    }

    .landing-container > div {
        text-align: center;
    }

    .landing-container > div > p {
        width: 60%;
        margin: 0 auto 5%;
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
    }

    .landing-container > div > p:nth-child(2) {
        width: 68%;
    }

    .landing-container > a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #fff;
        background-color: #53CF85;
        border-radius: 20px;
        width: 55%;
        padding: 4%;
        margin: 2% auto;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
    }

    .landing-container > p {
        color: #3D3C3C;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.28px;
    }

    .landing-container > p > a {
        color: #4A4A4A;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }
    
}